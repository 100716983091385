<template>
  <div class="shop">
    <!-- 标题 -->
    <!-- <div>
      <van-sticky>
        <van-nav-bar title="便民商店" />
      </van-sticky>
    </div> -->
    <!-- tab栏 -->
    <div class="information">
      <!-- 全部 -->
      <van-list v-model="loading"
        :finished="finished"
        offset="50"
        @load="onLoad">
        <div class="center"
          v-for="item in list"
          :key="item.index">
          <!-- <router-link :to="'/article/detail/' + item.RIId"> -->
          <div class="Content"
            style="padding: 8px 10px;line-height: 30px;">
            <div style="display: inline-block;"
              @click="xiangqing(item.ID)">
              <span style="font-size: 18px; color: #333">
                <van-icon name="manager" />{{ item.Name }}
                <van-icon name="phone" />{{ item.Mobile }}</span>
              <br />

              <span class="centertime"
                style="font-size: 18px;color: #333">
                报备时间：{{ item.AddTime }}
              </span>
              <br />
              <!-- <span class="centertime"
                style="font-size: 18px;color: #333">
                核酸检测次数：{{ item.NACheckCount }}
              </span> -->
              <span class="centertime"
                style="font-size: 18px;color: #333">
                停留地点：{{ item.Address }}
              </span>
            </div>
            <div style="display: inline-block;width: 95%;text-align: right;"
              class="ContentB">
              <span class="centertime"
                style="color: #333">
                <van-button type="info"
                  round
                  style="width: 80px;font-size:13px;"
                  @click="shopUpdata(item.ID)"
                  size="small">核酸报备</van-button>
              </span>
            </div>
          </div>
          <!-- </router-link> -->
        </div>
      </van-list>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
      <!-- <div>
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
          <van-button round
            type="info"
            size="normal"
            style="text-center: right; font-size: 16px; width: 100%"
            @click="addShop()"
            color="#617bfa">新增商铺</van-button>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { WxGetReportingPage } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  data () {
    return {
      riKind: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  methods: {
    // 员工信息
    entYuanGong (row) {
      console.log(row);
      this.$router.push({
        // name: "ygList",
        path: "/shop/myShop/ygList/" + row.ShopId + "/" + 2,
        // query: { seId: row.EId, ekind: 1 },
      });

    },
    //新增
    addShop () {
      this.$router.push({
        name: "addShop",
        query: { bfaId: this.itemmark, add: 1 },
      });
    },
    //重新绑定
    shopBangDing (shopId) {
      this.$router.push({
        name: "addShop",
        query: { shopId: shopId, add: 3 },
      });
    },
    //编辑
    shopUpdata (ID) {
      console.log(ID);
      this.$router.push({
        path: "/register/fangyi/myFangKong/fkFanQi/fanQiHs/" + ID,
      });
    },
    //详情
    xiangqing (ID) {
      console.log(ID);
      this.$router.push({
        path: "/register/fangyi/myFangKong/fkFanQi/mkfqXiangQing/" + ID,
      });
    },
    // 获取分页列表
    getList () {
      console.log(getOpenId());
      WxGetReportingPage({ openID: getOpenId() })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
                if (this.list[i].ShopImgUrl != "rejected") {
                  this.list[i].ShopImgUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => { });
    },

    // 下拉刷新
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick (e) {
      this.riKind = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted () {
    this.getList();
  },
};
</script>
<style>
.shopTitle {
  color: #333;
  padding: 10px;
}
.information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.center {
  width: 92%;
  margin: 10px auto;
  background: rgb(246, 243, 243);
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>